import moment from 'moment';
import Encoding from 'encoding-japanese';
import { parse, stringify } from 'csv/lib/es5';
import { downloader } from '@/lib/commonUtil';

export const readCsv = (file, callback) => {
  const reader = new FileReader();

  reader.onload = e => {
    const codes = new Uint8Array(e.target.result);
    const encoding = Encoding.detect(codes);
    const unicodeString = Encoding.convert(codes, {
      to: 'unicode',
      from: encoding,
      type: 'string',
    });

    parse(unicodeString, {}, (err, output) => {
      callback(output, null);
    });
  };

  reader.onerror = error => {
    callback(null, error);
  };

  reader.readAsArrayBuffer(file);
};

// CSVをダウンロードする
export const downloadCsv = (input, header, fileName) => {
  stringify(input, { header: true, columns: header }, function(err, output) {
    let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    let blob = new Blob([bom, output], { type: 'text/csv;charset=utf-16;' });
    downloader(blob, fileName);
  });
};

// CSV のファイル名を取得する
export const getCsvFileName = title => {
  // 現在時刻
  const currentTime = moment().format('YYYYMMDDHHmmss');

  return title + '_' + currentTime + '.csv';
};

// CSV の重複値群を取得する
export const getCsvDuplicateValues = (key, fileData) => {
  // カラムID
  const columnId = fileData[0].indexOf(key);
  // 値群
  const values = fileData
    .filter((data, index) => index !== 0)
    .map(data => data[columnId]);

  return values;
};
